@import "../../styles/constants";

.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(6, 3, 2, .9);
  z-index: 99999;
  overflow-y: auto;
}

.close {
  position: absolute;
  display: flex;
  align-items: center;
  padding-left: 20px;
  left: 100%;
  min-width: 50px;
  font-size: 14px;
  cursor: pointer;
  color: #ccc;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 25px;
    background-image: url(../../images/close.png);
    width: 12px;
    height: 12px;
    opacity: .8;
  }

  &:hover {
    color: #fff;

    &:before {
        opacity: 1;
    }
  }
}

.dialogWrap {
  display: flex;
  flex-direction: column;
}

.dialogImageWrap {
  position: relative;
  overflow: hidden;
}

.dialogImageWater {
  position: absolute;
  bottom: -20px;
  width: 100%;
  height: 163px;
  background-image: url(../../images/water_big_113.png);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  opacity: .7;
}

.dialogImage {
  position: relative;
  min-width: 629px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  overflow: hidden;
}

.dialogBackgroundImage {
  position: absolute;
  width: 700px;
  height: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: .4;
}

.dialogDescription {
  padding: 10px 0;

  ::-webkit-scrollbar { 
    background-color: transparent; 
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-color: transparent;
  }
}

.descriptionText {
  padding: 10px 0;
  font-size: 16px;
  line-height: 20px;
  max-height: 100px;
  overflow-y: auto;
}

.dialogDescriptionTitle {
  display: flex;

  &.center {
    justify-content: center;
  }
}

.dialogDescriptionName {
  font-size: 18px;
  font-weight: 700;

  &.uppercase {
    text-transform: uppercase;
  }
}

.gold {
  color: $colorGold;
}

.dialogDescriptionTypeIcon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
}

.fogMask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-mask-image: url(../../images/gradient_mask.svg);
  -webkit-mask-position: center;
  -webkit-mask-size: 1000px 450px;
  z-index: 1;
}

.fog1 {
  width: 300vw;
  height: 100%;
  position: absolute;
  background: url(../../images/fog-1.png) repeat-x;
  background-size: contain;
  background-position: center;
  -webkit-animation: marquee 200s linear infinite;
  animation: marquee 200s linear infinite;
  will-change: transform;
}

.fog2 {
  width: 300vw;
  height: 100%;
  position: absolute;
  background: url(../../images/fog-2.png) repeat-x;
  background-size: contain;
  background-position: center;
  -webkit-animation: marquee 160s linear infinite;
  animation: marquee 160s linear infinite;
  will-change: transform;
}

.overflowShadows {
  position: relative;
  height: 0;
  box-shadow: 0 6px 22px 7px rgba(0, 0, 0, 0.7);
}

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-200vw, 0, 0);
    transform: translate3d(-200vw, 0, 0);
  }
}

@keyframes marquee {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-200vw, 0, 0);
    transform: translate3d(-200vw, 0, 0);
  }
}