.categoryBody {
  display: flex;
  padding: 10px 0 30px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;

  &.withFooter {
    padding-bottom: 0;
  }
}

.categoryBodyWrapper {
  padding: 0 260px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.categoryBodyContent {
  width: 100%;
  padding: 0 5px;
  min-width: 1000px;
  max-width: 1300px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(205px, 1fr));
  justify-items: center;
  justify-content: space-between;
  gap: 40px 20px;
  box-sizing: border-box;

  @media (max-width: 1550px) {
    grid-template-columns: repeat(5, 175px);
    padding: 0;
  }
}

.filterNotFound {
  color: #f2f2f7;
  font-size: 44px;
  font-weight: bold;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  grid-column: 1 / 7;
}

.categoryWrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 260px;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.categoryWrapper {
  position: absolute;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  pointer-events: none;
}

.categoryWrapperContent {
  width: 1300px;
  display: flex;
  flex-wrap: wrap;
}

.buttonToTop {
  position: absolute;
  right: -100px;
  bottom: 55px;
  width: 40px;
  height: 40px;
  background-image: url(../../images/scroll_to_top.png);
  cursor: pointer;
  z-index: 99;
  opacity: 0;
  transition: opacity .5s;
  will-change: opacity, transition;
  pointer-events: initial;

  &.visibleScrollTop {
    opacity: .7;
  }

  &:hover {
    opacity: 1 !important;
  }

  @media screen and (max-width: 1280px) {
    left: 200px;
  }

  @media screen and (min-width: 1700px) {
    right: -90px;
  }
}

.filterWrap {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}

.filterBaseLeftPanel {
    position: relative;
}

.cursorDisabled {
  cursor: initial;
  pointer-events: none !important;
}

.categoryHeader {
  position: relative;
  padding: 0 260px 0 260px;
  min-width: 1000px;
  width: 70vw;
  max-width: 1300px;
  margin: 0 auto;
  z-index: 2;
}

.categoryHeaderTitleWrapper {
  border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.categoryHeaderTitle {
  padding: 25px 0;
  text-align: center;
  font-size: 16px;

  @media screen and (max-width: 1380px) {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
  }

  & > a {
    color: #fff;
    transition: border-bottom .2s ease-in-out;
    border-bottom: 1px solid rgba(255, 255, 255, .6);

    &:hover {
        border-bottom: 1px solid rgba(255, 255, 255, 1);
    }
  }
}

.overflowShadows {
  position: relative;
  height: 8px;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0, rgba(0, 0, 0, 0));
  z-index: 999;
  box-sizing: border-box;

  &.visibleShadows {
    opacity: 1;
  }
}

.categoryBodyShadows {
  width: 100%;
  padding: 0 10px;
  min-width: 1000px;
  max-width: 1300px;
  margin: 0 auto;
}