@import "../../styles/constants";

.item {
  position: relative;
  width: 205px;
  height: 120px;
  box-sizing: border-box;
  overflow: hidden;

  &.notOwn {
    opacity: .45;
  }
}

.isUnique {
  border: 1px solid transparent;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .6;
    background-image: url(./images/light_for_spc.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.isRare {
  border: 1px solid rgba(255, 204, 0, .5);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .6;
    background-image: url(./images/light_for_rare.png);
    background-size: 100% 100%;
  }
}

.nationWrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.nationIcon {
  max-width: 27px;
  margin-right: 10px;
}