.footer {
    margin-top: 30px;
    width: 100%;

    & :global(.wcf-main-container) {
        --wcf-condensed-font: Roboto Condensed;
        --wcf-font: Roboto, Helvetica, Arial, sans-serif;
        --main-bg: #00000042;
        --dropdown-bg: #0a0a0a;
    }
}