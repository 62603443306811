@import "mixin";
@import "fonts";
@import "constants";

:global {

    html,
    body,
    #app {
        padding: 0 !important;
        margin: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        --webkit-user-select: none;
        user-select: none;
    }

    body {
        background-color: transparent !important;
        color: #ddd;
    }

    #app {
        background-color: #060302 !important;
        background-image: url(../images/background.jpg);
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
    }

    button {
        cursor: pointer;
    }

    * {
        font-family: "Roboto Condensed", Arial, "Helvetica Neue", Helvetica, sans-serif;
    }

    a {
        text-decoration: none;
    }

    #wows-react-tooltip-body {
        max-width: 1000px;
        background: rgba(6, 3, 2, 0.9);
        box-shadow: none;

        & div[class^='Tooltip_tooltipBody'],
        & div[class^='_tooltipBody'] {
            max-width: 350px;
        }
    }

    #TooltipContent {
        min-width: 350px;
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        border: 1px solid rgba(255, 255, 255, .1);
        border-radius: 6px;
        background: rgba(0, 0, 0, .3);

        &:hover {
            border-color: rgba(255, 255, 255, .2);
            background: rgba(0, 0, 0, .5);
        }
    }

    .step {
        opacity: 0;
        transform: translateY(20px);

        @for $i from 1 through 15 {
            &.step-active-#{$i} {
                @include step-active(#{($i - 1) * 100}ms);
            }
        }
    }

    #router-main-app {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .ProgressBar__small__290p3 {
        height: 8px !important;
    }
}

.barfixed {
    position: relative;
    padding: 0 260px 0 260px;
    min-width: 1000px;
    width: 70vw;
    max-width: 1300px;
    margin: 0 auto;
    z-index: 2;
}

.filterWrap {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
}

.smallIconNation {
    display: inline-block;
    width: 27px;
    height: 27px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.smallIconType {
    display: inline-block;
    width: 23px;
    height: 31px;
    background-position: center;
    background-size: 21px 20px;
    background-repeat: no-repeat;
}

.tooltipVehicleTitle {
    padding-left: 4px;
    padding-right: 4px;
    text-transform: uppercase;
}

.tooltipContentIconsWrap {
    display: flex;
    align-items: center;
}

.dialogWrap {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(6, 3, 2, .9); // rgba(6, 3, 2, .3);
    z-index: 99999;
    overflow-y: auto;
}

.dialogClose {
    position: absolute;
    display: flex;
    padding-left: 20px;
    align-items: center;
    left: 100%;
    font-size: 14px;
    cursor: pointer;
    color: #ccc;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 25px;
        background-image: url(../images/close.png);
        width: 12px;
        height: 12px;
        opacity: .8;
    }

    &:hover {
        color: #fff;

        &:before {
            opacity: 1;
        }
    }
}

.dialogHeader {
    position: relative;
}

.gold {
  color: $colorGold;
}

.bodyAnimationWrap {
    background: transparent !important;
}

.viewAppAnimation {
    width: 200px;
    height: 200px;
    opacity: 0;
    border-radius: 50%;
    transition: all .5s;
    background: #060302;
}

.viewAppNoRadius {
    opacity: 1;
    border-radius: inherit !important;
}

.viewAnimationButton {
    background-color: red;
    width: max-content;
    padding: 10px 20px;
    margin: 20px;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
    border: 0;
    outline: none;
}

.hidden {
    position: relative;
    display: none;
    overflow: hidden;
}

.filterBaseLeftPanel {
    position: relative;
}

.preTitles {
    padding: 25px 0;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, .2);
    font-size: 16px;

    & > a {
        color: #fff;
        transition: border-bottom .2s ease-in-out;
        border-bottom: 1px solid rgba(255, 255, 255, .6);

        &:hover {
            border-bottom: 1px solid rgba(255, 255, 255, 1);
        }
    }
}

div[class^='Processing__processing'] {
    z-index: 1000 !important;
    background-color: rgba(6, 3, 2, .3);
}

div[class^='CheckboxWithLabel__container'] {
    display: flex;
    align-items: center;
    max-height: 22px;
    min-width: 50px;
}

div[class^="Dialog__container"] {
    width: 700px;
}

div[class^="ProgressBar__base"] {
    border: 1px solid rgba(255, 255, 255, .3);
    background: rgba(0, 0, 0, .3);
}

div[class^="RadioStyles__caption"] {
    padding: 0 0 0 30px;
}

div[class^="Toggle__toggle"]:hover {
    border-color: #aaa;
}
