.searchWrap {
  position: relative;
  padding: 12px 0 0;
}

.searchInputWrap {
  position: relative;
  display: flex;
  align-items: center;
}

.searchInput {
  background: rgba(0,0,0, .5);
  border: 1px solid #172939;
  outline: none;
  font-size: 14px;
  padding: 6px 30px 6px 10px;
  color: #fff;
  width: 250px;

  &:hover,
  &:focus {
    border: 1px solid rgba(255, 255, 255, .6);
  }
}

.resetSearch {
  position: absolute;
  right: 0;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  background-image: url(./images/close.png);
  cursor: pointer;
}

.searchAbsolute {
  position: absolute;
  top: 5px;
  right: 260px;
}